define("discourse/plugins/DiscourseAnonymizer/discourse/discourse-anonymizer-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discourse-anonymizer", function () {
      this.route("actions", function () {
        this.route("show", {
          path: "/:id"
        });
      });
    });
  }
  ;
});